import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const AgencyListPage = ({ data, pageContext, location }) => {
  const { currentPage, numPages } = pageContext;
  const agencyProfiles = data.allMarkdownRemark.nodes;

  const renderPagination = () => {
    let items = [];
    const maxPagesToShow = 4; // adjust as needed

    // Adding the first page and previous arrow if not on the first page
    if (currentPage > 1) {
      items.push(<Link key="first" to="/agency/" className="relative m-0 inline-flex items-center rounded-tl rounded-bl border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"><ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" /></Link>);
      items.push(<Link key="prev" to={`/agency/${currentPage - 1}`} className="relative inline-flex items-center border-t border-r border-b border-gray-300 bg-white px-4 py-2 text-sm"><ChevronLeftIcon className="h-5 w-5" aria-hidden="true" /></Link>);
    }

    // Calculate the range of pages to show
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(numPages, startPage + maxPagesToShow - 1);

    // Generate page number links
    for (let i = startPage; i <= endPage; i++) {
    const linkTo = i === 1 ? "/agency/" : `/agency/${i}`;
      items.push(
        <Link key={i} to={linkTo} className={currentPage === i ? "relative z-10 inline-flex items-center bg-zinc-200 px-4 py-2 text-sm text-black focus-visible:bg-zinc-300 border border-black -top-[5px]" : "relative inline-flex items-center px-4 py-2 text-sm text-gray-900 border-t border-b border-gray-300 border-r hover:bg-gray-50 focus:z-20 -top-[5px]"}>{i}</Link>
      );
    }

    // Ellipsis and last page if there's a gap between the last page and pages shown
    if (endPage < numPages) {
      if (endPage < numPages - 1) {
        items.push(<span key="ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm text-gray-900 border-t border-b border-gray-300 border-r hover:bg-gray-50 focus:z-20 -top-[5px]">...</span>);
      }
      items.push(<Link key={numPages} to={`/agency/${numPages}`} className="relative inline-flex items-center px-4 py-2 text-sm text-gray-900 border-t border-b border-gray-300 hover:bg-gray-50 -top-[5px]">{numPages}</Link>);
    }

    // Adding the next arrow if not on the last page
    if (currentPage < numPages) {
      items.push(<Link key="next" to={`/agency/${currentPage + 1}`} className="relative inline-flex items-center rounded-tr rounded-br border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"><ChevronRightIcon className="h-5 w-5" aria-hidden="true" /></Link>);
    }

    return <div>{items}</div>;
  };

  if (agencyProfiles.length === 0) {
    return (
      <Layout location={location} title="No Profiles">
        <p>No agency profiles found...</p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title="Agency Profiles">
      <Seo title="Agency Profiles" />
      <div className="p-5 md:p-10 text-sm bg-zinc-100">
      <h1 className="font-bold text-xl mb-5 border-b pb-4 border-gray-600">AGENCY PROFILES</h1>    
        <ul className="divide-y divide-gray-200">
          {agencyProfiles.map(profile => {
            const title = profile.frontmatter.title || profile.fields.slug;
            return (
              <li key={profile.fields.slug} className="py-4 flex">
                
                <div className="mr-4 flex-shrink-0">
                  <Link to={profile.fields.slug} className="post-link">
                    <img src={profile.frontmatter.logo} alt={`${profile.fields.title} Logo`} className="w-28" />
                  </Link>
                </div>

                <div className="">
                  <Link to={profile.fields.slug} className="">
                    <h2 className="font-semibold">{title}</h2>
                    <p className="text-sm" dangerouslySetInnerHTML={{ __html: profile.frontmatter.description || profile.excerpt }} />
                  </Link>
                </div>
                
              </li>
            );
          })}
        </ul>

        <div className="py-5">
          {renderPagination()}
        </div>
        
      </div>
    </Layout>
  );
};

export default AgencyListPage;

export const pageQuery = graphql`
  query AgencyListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/agency/profile/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          logo
          description
        }
      }
    }
  }
`;
