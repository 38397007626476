import * as React from "react"
import Header from "./header.js"
import Footer from "./footer.js"

const Layout = ({ location = {}, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  // Use a default value for pathname
  const pathname = location.pathname || "/";
  const isRootPath = pathname === rootPath;
  let header


  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
