import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import { Bars2Icon } from '@heroicons/react/24/solid';
import Logo from "../images/connected.svg";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Function to handle the Esc key event
  const handleKeyDown = (e) => {
    if (e.keyCode === 27) setIsNavOpen(false);
  };

  // Debounce function to limit the number of event calls
  const debounce = (func, wait = 10, immediate = true) => {
    let timeout;
    return function executedFunction(...args) {
      const context = this;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // Function to check scroll position
  const checkScroll = () => {
    const scrolled = window.scrollY > window.innerHeight * 0.3;
    if (scrolled) {
      document.querySelector('nav').classList.add('fixed-nav');
    } else {
      document.querySelector('nav').classList.remove('fixed-nav');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('scroll', debounce(checkScroll));

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('scroll', debounce(checkScroll));
    };
  }, []);

  return (
    <nav className="bg-zinc-100 text-black">
      <div className="">
        <div className="flex justify-between pl-5 md:px-0 border-b border-t md:border-t-0 border-gray-600">
          <div className="flex w-full md:px-5">
            <div className="md:flex-shrink-0 py-5 md:py-0 flex items-center">
                <Link to="/" itemProp="url"><img src={Logo} alt="Logo" className="w-10" /></Link>
                <Link to="/" itemProp="url" className="px-3 bg-zinc-100">
                <h1 className="uppercase font-semibold text-sm md:text-md">The Web Design Los Angeles</h1>
                </Link>
            </div>
            <div className="hidden md:flex w-full md:w-auto items-center justify-center">
              <Link to="/agency" className="p-5 text-sm font-medium border-l md:border-r border-black">Agencies Profiles</Link>
              <Link to="/search" className="p-5 text-sm font-medium md:border-r border-black">Search Agencies</Link>
              <Link to="/blog" className="p-5 text-sm font-medium md:border-r border-black">Blog</Link>
            </div>
            <div className="md:hidden flex items-center justify-center w-20 md:px-0 z-40" onClick={() => setIsNavOpen((prev) => !prev)}>
              <Bars2Icon className="h-10 w-10 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>

        {isNavOpen && (
          <div className="flex justify-center flex-col px-20 text-center gap-5 bg-gray-900 bg-opacity-75 backdrop-blur-md text-white py-4 h-screen w-full fixed top-0 z-30">
            <Link to="/agency" className="py-2 text-lg font-medium hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Agencies Profiles</Link>
            <Link to="/search" className="py-2 text-lg font-medium hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Search Agencies</Link>
            <Link to="/blog" className="py-2 text-lg font-medium hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Blog</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
